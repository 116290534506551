::-webkit-scrollbar{width: .6rem;background: rgb(237, 237, 237);}a{color: #003470;}::-webkit-scrollbar-thumb{background:hsl(0, 0%, 17%);}.splide__progress{display:none!important;}.container{width: 90vw;margin:3vh auto;margin-top:18vh;}.header{position:fixed;background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 32%, rgba(0,52,112,1) 88%);width:100%;top:0;z-index:1000;border-bottom:1px solid #6DF76D;}.navBarListHide{display:flex;flex-direction:column;gap:1.5rem;margin-left:1.5rem;margin-top:1rem;padding-top: 1rem;}.burger{position:relative;width:40px;height:30px;background:transparent;cursor:pointer;display:none;margin-top:0.4rem;}.burger input{display:none;}.burger span{display:block;position:absolute;height:4px;width:2.49rem;background:#003470;border-radius:9px;opacity:1;left:0;transform:rotate(0deg);transition:.25s ease-in-out;}
.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;}
h1, h2, h3, strong{
  color:#003470;
}
.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;}
.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);}
.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;}
.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;}
.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;} 
.navbarTop{
  display: flex;
  justify-content: space-between;
  margin: 1rem 2.5rem;}
.navBarList{
  display: flex;
  gap: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 1rem 2.5rem;}
.navBarList > a {
  cursor: pointer;
  letter-spacing: 0.3px;
  font-weight: 500;}
.navBarListHide > a{
 cursor: pointer;
  letter-spacing: 0.3px;
  font-weight: 500;
  display: flex;
  align-items: center; 
}
.navBarListHide > span{
  font-size: larger;
  letter-spacing: 0.3px;
  font-weight: 700;
  color: #01366c;
}
.cartText{
  display: block;
  font-weight: 600;
  font-size: 1.1rem;
color: #6DF76D;}
.cartLogo{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  z-index: 100;}
.cartLength{
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  font-size: 1rem;
  right: 0.6rem;
  font-weight: 800;
  background-color: #6DF76D;
  color: #003470;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;}
.wrapper{
  margin: 3vh 0;
  border-radius: 5px;}
.wrapperProduct{
  margin: 3vh 0;
  width: "33vw";
  border-radius: 5px;
  height: 100%;}
.wrapperSlideFooter{
  margin: 3vh 0;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #6DF76D}
.card {
 max-width: 90%;
 height: 424px;
 padding: .8em;
 background: #ffffff;
 position: relative;
 border: 1px solid rgba(72, 71, 71, 0.144);
 cursor: pointer;
 margin-top: 1rem;
 margin-bottom: 0.9rem;
 border-radius: 5px;
 transition: 0.4s ease-in-out;}
.card:hover{
  box-shadow: #0000001d 0px 7px 11px 0px;}
.card-img {
 height: 60%;
 width: 100%;
 border-radius: .5rem;
 image-resolution: 100%;}
.card-footer {
 width: 100%;
 display: flex;
 align-items: center;
 margin-top: 10px;}
.shipping{
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0.3rem;}
.price {
 font-weight: 600;
 font-size: 1.1em;
 line-height: 1.5;
 letter-spacing: 1.5px;
 margin-right: 0.5rem;
 color: green;}
.noPrice{
 font-weight: 600;
 font-size: 1em;
 line-height: 1.5;
 text-decoration: line-through;
 letter-spacing: 1.5px;
 color: rgb(97, 97, 97);}
.textTitle{
  font-weight: 600;
  font-size: 1.2em;
  color: #003470;}
.whatsApp {
  display: flex;
  height: 4rem;
  width: 4rem;
  background-color: #25D366;
  position: fixed;
  z-index: 10000;
  bottom: 1%;
  right: 1%;
  border-radius: 50%;}
.whatsApp svg {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
  cursor: pointer;}
.whatsApp .social-link4 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: whitesmoke;
  text-decoration: none;
  transition: 0.25s;
  border-radius: 50%;}
.whatsApp svg {
  transform: scale(1);}
.whatsApp .social-link4:hover {
  background-color: #12a50b;
  animation: bounce_613 0.4s linear;}
@keyframes bounce_613 {
  40% {
    transform: scale(1.4);  }
  60% {
    transform: scale(0.8);  }
  80% {
    transform: scale(1.2);  }
  100% {
    transform: scale(1);  }
}
.footerTop{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid rgba(72, 71, 71, 0.144);}
.footerCard {
  position: relative;
  width: 20em;
  height: 9.5em;
  background: white;
  border-radius: 15px;
  overflow: hidden;}
.footerCardHeading {
  position: relative;
  color: #003470;
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 0.6em;
  padding-left: 1em;}
.footerCardDetails {
  position: relative;
  color: black;
  font-size: 1em;
  padding-top: 1.5em;
  padding-left: 1.6em;
  font-weight: 500;}
.footerCardImg {
  position: relative;
  top: -7.5em;
  left: 17.5em;
  width: 70px;
  height: 70px;}
.footerBottom{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;}
.footerBottomTitle{
  font-weight: 800;
  font-size: large;
  display: flex;
  flex-direction: column;}
.footerBottomTitle > * {
  margin-top: 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: medium;
  text-decoration: none;}
.productContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;}
.detailsProduct{
  background-color: white;
  width: 50vw;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(72, 71, 71, 0.144);
  border-radius: 5px;}
.detailsProduct > *{
  margin: 0 2rem;}
.detailsProductSold{
  margin-top: 2rem;
  color: rgb(107, 107, 107);}
.detailsProductOffer{
  color: green;
  border-radius: 5px;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  width: 5rem;
  margin-top: 1rem;}
.detailsProductQuantity{
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  align-items: center;}
.detailsProductBuy{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;}
.productButton{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 3px;
  cursor: pointer;
  background-color: #6DF76D;
  border: 1px solid rgba(72, 71, 71, 0.144);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;color:#003470;}
.productButton:hover{
  background-color: #003470;color: #6DF76D;}
.productButton:active{
  transform: translateX(0.2em) translateY(0.1em);}
.descriptionProduct{
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(72, 71, 71, 0.144);}
.descriptionProduct > h2{
  margin: 1rem 2rem;}
.descriptionProduct > span > p{
  margin: 1rem 2rem;
  color: rgb(107, 107, 107);}
.detailsProductVariations{
  display: grid;
  margin-top: 0.5rem;
  margin-left: 1.7rem;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));}
.radio-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;}
.radio-inputs >*{
  margin: 6px;}
.radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;}
.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;}
.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;}
.radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;}
.radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;}
.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;}
.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;}
.radioTile{
  width: "32.8vw",
}
.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;}
.radio-tile:hover {
  border-color: #2260ff;}
.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;}
.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;}
.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;}
.number-control {
  display: flex;
  align-items: center;}
.number-quantity {
  padding: 0.25rem;
  border: 0;
  width: 3rem;
  height: 21.7px;
  border: 1px solid rgba(72, 71, 71, 0.144);
  align-items: center;
  text-align: center;
  border-radius: 0;}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;}
.accordion{
  border-radius: 5px;
  width: 100%;
  background: white;
  color: black;
  overflow: hidden;
  border: 1px solid rgba(72, 71, 71, 0.144);
  margin-bottom: 1rem;}
.accordion-header{
  position: relative;
  overflow: hidden;
  z-index: 2;}
.accordion-header-content{
  padding: 1rem;
  overflow: hidden;
  position: relative;
  z-index: 9;}
.accordion-content {
  padding: 10px;
  position: relative;
  overflow: hidden;
  list-style-type: disc;}
.accordion-content-text {
  color: #000000c1;
  font-size: 13px;
  list-style-type: disc;}
.accordion-content-text > h3{
  padding: 4.5px 0px;
  font-weight: 500;}
details summary {
  cursor: pointer;
  transition: all 225ms ease-out;
  position: relative;}
details[open] summary {
  margin-bottom: 100px;
  overflow: hidden;
  position: relative;
  background: rgba(204, 204, 204, 0.445);}
details[open] .accordion-content {
  padding: 1rem;
  margin-top: -100px;}
details > summary {
  list-style: none;}
details > summary::-webkit-details-marker {
  display: none;}
summary::after{
  content: '+';
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 26px;}
details[open] summary::after {
  content: '-';
  font-size: 30px;}
.searchContainer{
  display: flex;
  width: 70vw;
  justify-content: center;
  align-items: center;
  position: relative;}
.searchHide{
  display: none;
  width: 70vw;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;}
.searchInput{
  width: 100%;
  position: absolute;
  border-radius: 5px;
  padding-left: 1rem;
  border: 1px solid rgba(72, 71, 71, 0.144);
  background-color: white;
  height: 5vh;}
.searchInput2{
  width: 100%;
  position: absolute;
  border-radius: 5px;
  padding-left: 1rem;
  border: 1px solid rgba(72, 71, 71, 0.144);
  height: 5vh;
  padding-right: 4rem;}
.searchInput:focus{
  outline: 1px solid blue;}
.searchInput2:focus{
  outline: 1px solid blue;}
.searchLogo{
  position: absolute;
  top: 0.95rem;
  right: 0;
  border-left: 1px solid rgba(72, 71, 71, 0.144);
  padding-left: 0.3rem;
  cursor: pointer;
  background-color: white;}
.searchLogo2{
  display: none;}
.containerSearch{
  display: flex;
  justify-content: space-between;}
.searchProductsContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 70vw;
  gap: 0.5rem;}
.filtersSearch{
  background-color: white;
  width: 16.8vw;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.105);
  padding: 1.5rem 1rem 1rem 1.5rem;}
.filtersSearch > span{
  color: gray;}
.filtersTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;}
.filtersTitle > span{
  font-size: 1.3rem;}
.cartContainer{
  position: fixed;
  background-color: white;
  z-index: 1000;
  width: 28vw;
  height: 100%;
  top: 0;
  right: 0;
  padding: 3.5rem 2rem;
  transition: .3s;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  box-shadow: #00000053 0px 7px 11px 0px;}
.cartTop{
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;}
.cartTop > strong{
  font-size: 1.5rem;}
.cartMiddle{
  width: 100%;
  height: 55%;
  overflow: auto;}
.cartEmpty{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
}
.cart__card {
  display: grid;
  grid-template-columns: .5fr 1fr;
  column-gap: 1rem;
  padding: .5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.118);
  border-top: 1px solid rgba(0, 0, 0, 0.118);}
.cart__box {
  width: clamp(80px, 100%, 150px);
  margin: 0 auto 1rem;}
.cart__img {
  width: 100%;
  height: 8rem;
  object-fit: contain;
  border: none;}
.cart__title {
  font-size: 1rem;
  margin-bottom: .5rem;
  display: flex;
  justify-content: space-between;}
.cart__stock {
  display: block;
  font-size: .875rem;
  color: hsl(0, 0%, 75%);
  margin-bottom: 0.5rem;}
.cart__price {
  font-size: .875rem;
  color: rgb(0, 0, 0);
  margin-bottom: 0.5rem;}
.cart__checkout{
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  gap: 1rem;}
.cart__subtotal{
  display: flex;
  justify-content: flex-end;
  font-size: .875rem;}
.cart__subtotal-price {
  font-size: 1rem;
  color: green;
  font-weight: 700;}
.cart__amount,
.cart__amount-content {
  display: grid;
  column-gap: 0.1rem;
  align-items: baseline;}
.cart__amount {
  display: flex;
  justify-content: space-between;}
.cart__amount-content {
  grid-template-columns: 1fr 2fr 1fr;}
.cart__amount-number {
  display: flex;
  justify-content: center;}
.cart__amount-box {
  display: flex;
  padding: .25rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.118);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 21.7px;}
.cart__amount-trash{
  cursor: pointer;}
.cart__prices {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;}
.cart__prices-item,
.cart__prices-total {
  color: hsl(0, 0%, 15%);}
.cart__prices-item {
  font-size: .875rem;}
.cart__prices-total {
  font-size: 1rem;
  font-weight: 500;}
.cart__btn {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 15%);}
.cart__btn:disabled {
  cursor: not-allowed;
  opacity: .5;}
.cart__btn:disabled:hover {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 15%);}
.cartBottom{
  width: 100%;
  height: 20%;}
.cartQuantity{
  display: flex;
  justify-content: space-between;
  align-items: center;}
.cartProducts{
  font-size: 1.3rem;}
.cartTotal{
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;}
.cartButtons{
  display: flex;
  justify-content: center;
  align-items: center;}
.cartButtons > a{
  margin-top: 1rem;
  width: 100%;
  height: 3rem;}
.containerBlog {
  background: white;
  border-radius: 5px;
  padding: 1rem 2rem;}
.cardBlog {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease;}
.cardBlog:hover {
  transform: scale(1.05);}
.horizontalBlog {
  flex-direction: row;
  grid-row: span 1;
  grid-column: span 2;}
.reverse-horizontalBlog {
  flex-direction: row-reverse;
  grid-row: span 1;
  grid-column: span 2;}
.horizontalBlog .card__img_blog, .reverse-horizontalBlog .card__img_blog {
  height: 1;}
.card__content_blog {
  padding: 16px;}
.card__type_blog {
  font-variant: small-caps;
  letter-spacing: 2px;
  opacity: 0.9;
  color: palevioletred;
  padding-bottom: 0.5em;}
.card__title_blog {
  font-size: 18px;
  opacity: 0.8;
  font-weight: 700;
  padding-bottom: 0.5em;
  transition: all 0.25s ease;}
.card__date_blog {
  font-size: 14px;
  padding-bottom: 0.5em;
  opacity: 0.3;}
.card__time-to-read_blog {
  font-style: italic;  }
.card__excerpt_blog {
  font-size: 16px;
  padding-bottom: 0.5em;}
.card__tags_blog {
  margin-top: auto;}
.tagBlog {
  display: inline;
  background: papayawhip;
  padding: 0.5em;
  border-radius: 0.5em;
  font-size: 12px;
  color: palevioletred;
  user-select: none;
  cursor: pointer;}
.tagBlog:hover {
  background: darken(papayawhip, 5%);}
.tagBlog:active {
  background: darken(papayawhip, 10%);}
.tagBlog i {
  margin-right: 4px;}
.checkoutContainer{
  display: flex;
  width: 90vw;
  justify-content: center;  gap: 5rem;}
.cartMiddleCheck{
  width: 45;
  }
.checkoutForm{
  display: flex;
  flex-direction: column;
  width: 45vw;}
.checkoutForm > input{
  height: 3rem;
  border-radius: 5px;
  border: 1px solid rgba(72, 71, 71, 0.144);
  font-size: 14px;
  padding: 0rem 1rem;}
.checkoutForm > input:focus{
  outline: 1px solid blue;}
  .radioContent{
    display: block;
    padding: 0rem 1rem; 
    background-color: white;
    z-index: 1;
    margin-top: -0.3rem;
    border-left: 1px solid rgba(72, 71, 71, 0.144);
    border-right: 1px solid rgba(72, 71, 71, 0.144);
    max-width: 42.8vw;
    transition: 0.3s ease;
    color: rgba(0, 0, 0, 0.559);
  }
  .hidden{
  position: absolute;
  top: -10rem;
  color: transparent;
  }
  .submit{
    margin-top: 0.5rem;
    background-color: white;
    border: 1px solid rgba(72, 71, 71, 0.144);
    cursor: pointer;
  }
  .notFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
@media (max-width: 1000px){.cartContainer{width: 40vw;}
  .productContainer{grid-template-columns: 1fr;
    gap: 0rem;}
  .wrapperProduct{display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;}
  .detailsProduct{width: 90vw;}
  .descriptionProduct{width: 90vw;}
  .wrapperSlideFooter{width: 90vw;}
  .cartText{display: none;}
  .burger{display: block;
    margin-right: 2rem;}
  .navBarList{display: none;}
  .container{margin-top: 13vh;}
  .searchContainer{width: 58vw;}
  .navbarTop{margin: 1rem 4%;}
  .splide__arrow--next {font-size: 22px;
    margin-right: -1.35rem;}
  .splide__arrow--prev {font-size: 22px;
    margin-left: -1.35rem;}
  .searchProductsContainer{display: grid;
    grid-template-columns: repeat(3, 1fr);}
  .checkoutContainer{
  justify-content: space-between;}
.checkoutForm{
  width: 50vw;}
  .checkoutDetails{
    width: 36vw;
  }
  .radioContent{
    max-width: 46.6vw;
    margin-top: -0.06rem;
  }
  }
  @media (max-width: 905px){
    .checkoutContainer{
      flex-direction: column-reverse;
    }
    .checkoutForm{
      width: 90vw;
    }
    .cartMiddleCheck{
      width: 90vw;
      margin-bottom: -5rem;
    }
    .radioContent{
      max-width: 90vw;
    }
  }
  @media (max-width: 720px) {.footerTop{display: none;}
  .cartContainer{width: 90vw;
    margin-left: 3rem;}
  .footerBottom{justify-content: center;
    gap: 1rem;
    flex-direction: column;}
  .searchInput{display: none;}
  .searchLogo{display: none;}
  .searchLogo2{position: absolute;
    right: -2rem;
    padding-left: 0.3rem;
    cursor: pointer;
    border: none;
    display: block;
    background-color: transparent;}
  .searchHide{display: flex;
    position: fixed;
    margin: 0 auto;
    margin-top: 9.7vh;
    width: 60vw;
    left: 50%;
    transform: translateX(-50%);}
  .searchProductsContainer{display: grid;
    grid-template-columns: repeat(2, 1fr);}
  .textTitle{font-size: 1.05em;}
  .noPriceCard{font-size: 0.9em;
    letter-spacing: 0.6px;}
  .radioContent{
    max-width: 85.3vw;
  }
}
@media (min-width: 720px){.wrapperSlideFooter{display: none;}}